import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import OperaGrid from '../../components/operagrid'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull, ImageCenter } from '../../components/imageelements'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'
import BlockQuote from '../../components/blockquote'

const bibliography = [
  {name: 'Verdi With a Vengeance: An Energetic Guide to the Life and Complete Works of the King of Opera', writer: 'William Berger', url: 'https://amzn.to/3B3Qlyq'},
  {name: 'The Complete Operas Of Verdi', writer: 'Charles Osborne', url: 'https://amzn.to/38g9xN8'},
  {name: 'The Cambridge Verdi Encyclopedia', writer: 'Roberta Montemorra Marvin', url: 'https://amzn.to/3jhmMU3'},
  {name: 'The Lives of the Great Composers', writer: 'Harold C. Schonberg', url: 'https://amzn.to/3guutVb'},
];

const VerdiQuote = {content: 'I adore art... when I am alone with my notes, my heart pounds and the tears stream from my eyes, and my emotion and my joys are too much to bear.', quotee: 'Giuseppe Verdi'}
const VerdiQuote2 = {content: 'I went back home with Nabucco in my pocket. One day, one line; one day, another; now one note, then a phrase . . . little by little the opera was composed.', quotee: 'Giuseppe Verdi'}

const poperas = [
  {opera: 'Oberto', premiere: '17th November 1839'},
  {opera: 'Un giorno di regno', premiere: '5th September 1840'},
  {opera: 'Nabucco', premiere: '9th March 1842'},
  {opera: 'I Lombardi alla prima crociata', premiere: '11th February 1843'},
  {opera: 'Ernani', premiere: '9th March 1844'},
  {opera: 'I due Foscari', premiere: '3rd November 1844'},
  {opera: 'Giovanna d\'Arco', premiere: '15th February 1845'},
  {opera: 'Alzira', premiere: '12th August 1845'},
  {opera: 'Attila', premiere: '17th March 1846'},
  {opera: 'Macbeth', premiere: '14th March 1847'},
  {opera: 'I masnadieri', premiere: '22nd July 1847'},
  {opera: 'Jerusalem', premiere: '26th November 1847'},
  {opera: 'Il corsaro', premiere: '25th October 1848'},
  {opera: 'La battaglia di Legnano', premiere: '27th January 1849'},
  {opera: 'Luise Miller', premiere: '8th December 1849'},
  {opera: 'Stifellio', premiere: '16th November 1850'},
  {opera: '<a href="/operas/rigoletto">Rigoletto</a>', premiere: '11 March 1851'},
  {opera: 'Il Trovatore', premiere: '19th January 1853'},
  {opera: '<a href="/operas/traviata">La traviata</a>', premiere: '6th March 1853'},
  {opera: 'Les vêpres siciliennes', premiere: '13th June 1855'},
  {opera: 'Simon Boccanegra', premiere: '12th March 1857'},
  {opera: 'Aroldo', premiere: ' 16th August 1857'},
  {opera: 'Un ballo in maschera', premiere: '17th February 1859'},
  {opera: 'La forza del destino', premiere: '10th November 1862'},
  {opera: 'Don Carlos', premiere: '11th March 1867'},
  {opera: 'Aida', premiere: '24th December 1871'},
  {opera: 'Otello', premiere: '5th February 1887'},
  {opera: 'Falstaff', premiere: '9th February 1893'},
];



// markup
const VerdiPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Giuseppe Verdi | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/composers/verdi" />
          <meta name="description" content="A guide to the life and works of composer Giuseppe Verdi! All the operatic compositions, from Oberto to Falstaff." />
        </Helmet>
        <Hero title="Giuseppe Verdi"/>
        <MainBodySection>

            <BlockQuote quote={VerdiQuote}/>

            <ImageCenter>
              <StaticImage src="../../images/composers/verdi/Giuseppe_Verdi.jpg" alt="Giuseppe Verdi"/>
              <figcaption>Giuseppe Verdi</figcaption>
            </ImageCenter>

            <p>Giuseppe Verdi lived a life with more moments of tragedy than most of us could take. As a young man he lost his
              wife and both his children in the space of just a few years, and his home town of Le Roncole flip flopped
              between occupations by various foreign nations. His life also contained great triumph however. He was near
              exalted by many for his operatic achievements and his funeral is still claimed to be the largest public event in
              the history of Italy (with some putting the figure at 300,000 in attendance).</p>

            
            <div id="ezoic-pub-ad-placeholder-102"> </div>

            <ImageFull>
              <StaticImage src="../../images/composers/verdi/funeral_verdi.jpg" alt="The State Ceremony following Verdi's death on 27th February 1901"/>
              <figcaption>The State Ceremony following Verdi's death on 27th February 1901</figcaption>
            </ImageFull>
      
            <p>His operas were, with two notable exceptions, anything but comedies. Those exceptions, <i>Un Giorno di regno</i>
              and <i>Falstaff</i>, bookend his career, the former (his second opera) a complete disaster and the latter (his
              26th and final opera) a total triumph. Verdi supplied operas that provided audiences with what they wanted, in
              the words of musicologists <a href="https://www.amazon.com/gp/product/0231119585/ref=as_li_ss_tl?ie=UTF8&camp=1789&creative=390957&creativeASIN=0231119585&linkCode=as2&tag=beautiful2get-20">Grout and Williams</a>, his works were "serious, gloomy, and violent; the early works in
              particular are typical examples of the blood-and-thunder romantic melodrama". Yet despite the improbable and
              overblown narratives, Verdi's works have an incredible knack for making people laugh, cry and think.</p>

            <VideoPlayer src="https://www.youtube.com/embed/QZN01_pAxro?start=50&autoplay=1" id="QZN01_pAxro" title="The anvil chorus from Il Trovatore"/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="His Operas"/>

            <OperaGrid rows={poperas}/>

            <Header title="Biography"/>

            <p>The great Italian Giuseppe Verdi had his birth certificate written in French! He was born on October 10th 1813 in
                Le Roncole a small town near Busseto which was at that time under Napoleonic rule. His parents weren’t wealthy,
                his father Carlo Verdi ran a store, and Le Roncole was hardly a cultural hub, so Verdi first took a shine to
                music through attending his local church. Carlo (who it must be noted wasn't nearly as impoverished as Verdi
                liked to suggest later in his life) kindly bought his son an old Spinet (a small type of Harpsichord) and that
                was the start of Verdi and music.</p>

            <ImageFull>
              <StaticImage src="../../images/composers/verdi/Verdi_Birth.jpg" alt="The tavern in Le Roncole where Verdi was born"/>
              <figcaption>The tavern in Le Roncole where Verdi was born &copy; <a href="http://www.flickr.com/photos/renaud-camus/6514989947/">Renaud Camus</a></figcaption>
            </ImageFull>
        
            <p>He quickly learned to play under the village organist Pietro Baistrocchi and when Baistrocchi left the village,
                Verdi took over from him at the ripe old age of 12. Le Roncole was still a bit limited culturally however, so
                Verdi’s parents soon sent him to live in nearby Busseto where Verdi’s musical education could continue to
                blossom. He came under the wing of Ferdinando Provesi, the director of Busseto’s music school, who inspired
                Verdi by taking him to rehearsals of the local orchestra.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>
        
            <p>As he grew towards adulthood, Verdi was encouraged to continue his studies in Milan. In 1832 he travelled there
                but, in his first major setback, failed to be admitted to the Royal Imperial Conservatory (largely because of
                his age, the maximum was 14 and he was nearly 19). He studied privately with Vincenzo Lavigna instead, a
                Harpsichordist with connections to La Scala, who provided Verdi a wealth of access to operatic music.</p>

            <ImageRight maxwidth="250px">
              <StaticImage src="../../images/composers/verdi/margherita_barezzi.jpg" alt="Margherita Barezzi"/>
              <figcaption>Margherita Barezzi</figcaption>
            </ImageRight>
            
            <p>From this point onwards Verdi’s life would enter the realm of extreme success and tragedy that would itself make
                a perfectly decent operatic plot. Verdi returned to Busseto in 1835, replacing his old master Provesi who had
                recently died. He married the daughter of one of his earlier benefactors, Margherita Barezzi, and they soon had
                their first child Virginia. Verdi worked on his first Opera, <i>Oberto</i>, but life quickly took a considerable
                turn for the worse. Virginia died in 1838, his new son Icilio died in 1939 and though <i>Oberto</i> was a
                success at La Scala in November 1939 (a remarkable feat for a first time composer), Verdi would fall ill in 1840
                and lose Margherita by June of that year (She was only 26 taken by encephalitis).</p>
        
            <p>He turned out his second opera in 1840, a comic opera, which flopped so badly he didn’t tackle another comedy
                until near the end of his life. Verdi was crushed and had no will to work and for two years he didn’t get much
                done, assisting on a few revivals of <i>Oberto</i> about the extent of it. Bartolomeo Merelli, the impresario of
                La Scala who had championed Verdi’s work, wasn’t taking no for an answer however, and pushed Verdi to write what
                was to become the great <i>Nabucco</i>. </p>

            <BlockQuote quote={VerdiQuote2}/>
        
            <p>It was a colossal success when it opened in 1842 and thus began an absurdly productive period for Verdi, what
                  is now known as his “Galley Years”. Over the next decade he would write some 16 operas, largely following
                  the Italian model of opera laid out by Donizetti and Rossini before him. He began to break new ground
                  however, both in narrative structure: <i>Macbeth</i> for example lacking a major love story, and in his
                  musical forms: introducing elements of French opera.</p>

            <ImageRight>
              <StaticImage src="../../images/composers/verdi/StrepponiNabucco.jpg" alt="Giuseppina Strepponi in Nabucco"/>
              <figcaption>Giuseppina Strepponi in Nabucco</figcaption>
            </ImageRight>
          
            <p>This period was also a better one for Verdi personally. Though his enormous workload resulted in
              variable health, his success opened the door to Milanese society and he took up with the soprano
              Giuseppina Strepponi, who became his lifelong companion (if not for some time his wife...). In 1847
              he finally embarked on significant foreign travel, making his way to London and then Paris where he
              lived with Strepponi for two years. Their return to Busseto in 1849 caused something of a scandal as
              he and Strepponi were not married (and she had several illegitimate children including one by
              Merelli!).</p>
      
            <p>Verdi was assisted financially by the publisher Giovanni Ricordi, who championed him from the off
              (buying the rights to Oberto for the significant sum of 1,600 lire), and then maintained an almost
              exclusive contract with Verdi for the rest of his life (Giovanni would die in 1853 with first his
              son and then grandson taking over the business).</p> 
      
            <p>Verdi’s Galley years came to a close with three masterpieces in quick succession; <i>Rigoletto</i>, <i>Il
                  Trovatore</i> and <i>La Traviata</i> (premiering in March 1853). Tackling rich, challenging topics audiences
                  were initially taken aback (the first night of <i>La Traviata</i> remains one of the greatest disasters in
                  operatic history, as you can see from Verdi's brief letter below) but these works soon became popular smash
                  hits around the globe. From this point onwards Verdi’s output would slow considerably.</p>

            <ImageFull>
              <StaticImage src="../../images/composers/verdi/VerdiTravLetter.png" alt="Verdi letter after Traviata"/>
              <figcaption>Perhaps Verdi's most famous letter. Sent the day after <i>La Traviata</i>'s disastrous premiere.</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>
        
            <p>Over the next 18 years, Verdi would produce 6 operas while reworking some of his earlier works. He went off
                to Paris for two years to complete, <i>Les vêpres siciliennes</i>, an epic work in the French grand operatic
                style, and he would return there in 1866 for another year to work on <i>Don Carlos</i>. Otherwise he didn’t
                travel outside of Italy significantly and alongside revising a few works he dipped his toes in the political
                waters with a fairly unsuccessful stint in the new Italian parliament.</p>

            <VideoPlayer src="https://www.youtube.com/embed/-Ciez8ldky4?autoplay=1" id="-Ciez8ldky4" title="The epic auto-da-fé scene from Don Carlo. Here heard in
                the later Italian version (hence Carlo rather than Carlos), this is Verdi tackling a French
                format in an essentially Italian way."/>
        
            <p>Though his output reduced in quantity, the quality had never been higher. His operas became longer, bigger
                  and more diverse. Though he largely stuck to Romantic melodramas, his scope became greater with more
                  characters and more subplots working alongside the traditional love triangles. Comedy mixed with romance and
                  tragedy, <i>Un Ballo in maschera</i> a strong example of this more varied ideal. Unlike some of his younger
                  contemporaries however, he didn’t ditch many of opera’s formalities remaining relatively attached to formal
                  arias and set pieces.</p>
      
            <p>This conservatism was to play against Verdi as with the passing years it became increasingly clear
              that Verdi’s new works weren’t the popular smash hits of his earlier days. Though today we consider
              them every bit the equal of his earlier work at the time none of these post-Traviata works achieved
              steady places in the developing Italian repertory. Wagner’s influence was seeping across the border
              and Italy grew ever more cosmopolitan in taste.</p>
      
            <p>Verdi did little to fight this tide and effective retired to his estate at Sant’Agata, a place he had
              been cultivating over the last few decades. Though he remained a popular figure and supervised a
              range of productions of his work there would be no new operas for some 16 years. He did turn out his
              decidedly operatic Requiem, but otherwise these were years in which he spent more time acquiring
              land than he did composing.</p>

            <ImageFull>
              <StaticImage src="../../images/composers/verdi/VillaVerdi.jpg" alt="Villa Verdi at Sant'Agata"/>
              <figcaption>Villa Verdi at Sant'Agata &copy; <a href="http://www.flickr.com/photos/mastino70/5360120989/">Alessandro</a></figcaption>
            </ImageFull>

          </MainBodySection>


          <div id="ezoic-pub-ad-placeholder-106"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>
          
            <p>In 1879 Ricordi began to encourage Verdi towards Shakespeare’s Othello, a work Verdi had long admired. He
                  suggested Arrigo Boito as the librettist (a savvy choice though at times a tricky one, Boito being one of
                  the revolutionaries that had led to Verdi retreating in the first place), and with a draft libretto in place
                  Verdi set to writing. <i>Otello</i> opened in February 1887 at La Scala to Europe-wide excitement. Boito
                  would soon set Verdi going on <i>Falstaff</i>, a comedy based on “The Merry Wives of Windsor”, that after a
                  long gestation stormed the stage of La Scala in 1893.</p>

            <VideoPlayer src="https://www.youtube.com/embed/yx2zJMN4uEo?autoplay=1" id="yx2zJMN4uEo" title="The fabulous final scene from Verdi's final opera Falstaff"/>
        
            <p>These final works demonstrate a genius who had developed over his more than 50 years of composition. Verdi
                created music that flowed across the Acts, a sense of continuity more similar to the rising tide of Wagner
                and Puccini, albeit punctuated by lyrical moments (that are old school Verdi through and through).</p>
    
            <p>Verdi would compose little after <i>Falstaff</i>. Now enormously wealthy he devoted considerable efforts to
                philanthropic projects, including founding the Casa di Riposo per Musicisti, a retirement home for musicians
                that still exists today. Strepponi would die in November 1897, and Verdi would suffer a stroke and die not
                long after on January 27th 1901.</p>

            <Header title="Verdi and the Risorgimento"/>

            <p>Verdi’s incredible life contains a fair amount of myth and legend, much of it propagated by the man himself. His
              role, and that of his music, in the movement for Italian reunification, the Risorgimento, is a matter of some
              interest. Italy had been a divided country for centuries, sliced into pieces by Austria, France and Spain
              amongst others, and there’s no doubt Verdi was a staunch Nationalist who greatly yearned to see a unified Italy
              (who can blame him, it can’t be much fun being ruled by a different nation every few years!), but his operas',
              much hyped, effect on the movement doesn’t altogether stand up to scrutiny.</p>
        
            <p>First and foremost the stories of the instant impact of “Va Pensiero”, the hebrew chorus from <i>Nabucco</i>
                (Verdi’s third opera and one written at a time when Verdi had enough personal problems that Italy probably
                wasn’t the first thing on his mind) are essentially fiction. The tune it is claimed was taken up from the first
                performance as a battle song against the Austrian rulers. In reality it was nothing of the sort and it was only
                claimed by the Risorgimento years later.</p>

            <ImageFull>
              <StaticImage src="../../images/composers/verdi/La_Amos_Cassoli.jpg" alt="The Battle of Legnano by Amos Cassoli"/>
              <figcaption>The Battle of Legnano by Amos Cassoli</figcaption>
            </ImageFull>
        
            <p>Analysed with hindsight it’s also easy to write political motives into a good deal of Verdi’s early operas even
                if they aren’t really there. Both <i>Ernani</i> and <i>I Lombardi</i> have considerable revolutionary content,
                but neither seems to have been perceived as dangerously political at the time either by censors or audiences.
                For example, Milan was a turbulent place in the 1840s and when the Austrians reclaimed the city in 1848 they
                clamped down on any activities and ideas that might lead to civil unrest: yet La Scala presented <i>Ernani</i>
                and <i>Nabucco</i> over the next two seasons! This is not to suggest that these operas don’t contain plenty that
                might stir up an audience, but it seems that the audiences of that time did not associate these works with the
                current political climate. Perhaps the exception that proves the rule in Verdi's output is <i>La battaglia di
                    Legnano</i>, an overtly political work that quickly fell foul of the Austrian censors.</p>

            <ImageFull>
              <StaticImage src="../../images/composers/verdi/vivaVerdi.jpeg" alt="Italians writing Viva Verdi on the wall"/>
              <figcaption>Italians writing Viva Verdi on the wall</figcaption>
            </ImageFull>
        
            <p>Verdi was undeniably a keen supporter of the unification movement, he wrote extensive letters to his librettist
                Piave detailing his opinions, and it is not surprising as a result that his music would come, even
                retroactively, to be seen as political. The legend goes that in 1859, with the Austrian occupation coming to its
                end, crowds would shout “Viva Verdi”. A secret cry of - Viva Vittorio Emanuele Re D’Italia -- “Long live
                Vittorio Emanuele, King of Italy”. True or not, Verdi became a symbol of the Risorgimento, and that association
                is now frequently applied to his operas. That doesn’t mean however, that they’re all loaded with coded messages
                encouraging the downfall of the government...</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>


            <FunFactsHeader>Verdi's Nationality</FunFactsHeader>

            <ImageFull>
              <StaticImage src="../../images/composers/verdi/Verdi_passport.jpg" alt="Verdi's passport"/>
              <figcaption>Verdi's passport</figcaption>
            </ImageFull>  
      
            <p>The area Verdi was born in suffered considerable unheaval. At his Birth it was French occupied, by
                his teens the Austrians were in control. Little wonder he was so keen on Italian Unification! This
                meant that when he wanted to travel to Milan he had to get a passport. His passport makes for
                delightful if not very flattering reading:</p>
      
            <p>Age: eighteen, Height: tall, Colour of hair: chestnut-brown, Colour of eyes: grey, Forehead: high,
                Eyebrows: black, Nose: aquiline, Mouth: small, Beard: dark, Chin: oval, Face: thin, Complexion:
                pale, Special peculiarities: pock-marked.</p>
            
            <FunFactsHeader>Cooking</FunFactsHeader>
      
            <p>Verdi wasn’t just a great composer, he was also a pretty good cook! Strepponi, his wife, wrote the
                  following about a trip to the theatre. Verdi hoped to go incognito but was spotted, <i>“They began
                  to shout “Viva, Verdi”, and everyone, from the boxes to the pit, stood up to salute the Great
                  Composer from Le Roncole. If only they knew how well he composes <i>risotto alla Milanese</i>,
                  God knows what ovations would have been showered on his shoulders."</i></p>

            <ImageFull>
              <StaticImage src="../../images/composers/verdi/VerdiFood_MelchiorreDelfico_1858.jpg" alt="Cartoon of Verdi Cooking"/>
              <figcaption>Cartoon of Verdi Cooking</figcaption>
            </ImageFull>  
            
            <FunFactsHeader>Priests</FunFactsHeader>
      
            <p>Despite his early life in the church, or perhaps because of it, Verdi was no great fan of clerics
                (They turn up as villains in several of his opera's including the particularly beastly Grand
                Inquisitor in <i>Don Carlo</i>). One incident of note occurred when he was young and serving as an
                altar boy. The tale goes that he became lost in the music and failed to hear instructions from a
                priest. The priest kick him, knocking him down the altar stairs and Verdi cried “May God strike you
                with lightning!”</p>
      
            <p>Eight years later a church was struck by lightning killing four priests including the one that Verdi
                had cursed. Spooky no doubt and perhaps this event would later influence the potent curse in Verdi’s
                <i>Rigoletto</i>.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>
      
            <FunFactsHeader>London</FunFactsHeader>

            <ImageRight>
              <StaticImage src="../../images/composers/verdi/Her_Majesty's_Theatre_in_1867.jpg" alt="The Queen's Theatre burning, London 1867"/>
              <figcaption>The Queen's Theatre burning, London 1867</figcaption>
            </ImageRight>         
      
            <p>Verdi very nearly came to live in London in 1847. <i>I masnadieri</i> had been a tremendous success
                and the impresario Benjamin Lumley offered Verdi the position as director of the Queen’s Theatre,
                also known as the Theatre of the Italians (and today Her Majesty's Theatre where Phantom of the
                Opera has played for 25 years!). It never came to pass though Verdi was keen, particularly as
                Strepponi was a genuine Anglophile. Instead Verdi spent two years in Paris but not before writing
                this about London, <i>“With this constant smell of coal I feel I am always aboard a steamship”</i>.
                The Queen's Theatre would burn to the ground two decades later in 1867.</p>
                      
            <FunFactsHeader>Requiem</FunFactsHeader>
      
            <p>The bulk of Verdi’s output was in opera but he left a lasting mark with his unusual Catholic Requiem.
                Originally Verdi was only to write a small part of a Mass to commemorate Rossini, but after that
                fell apart, Verdi decided to write a complete requiem himself in spite of the fact that Verdi didn’t
                much love the church. </p>
      
            <p>He did it in honour of Alessandro Manzoni, an Italian poet and novelist with strong ties to the
                Italian unification movement. Verdi wrote of Manzoni in terms he scarcely used to describe anyone
                else, in one case writing, <i>“I would have gone down on my knees before him if we were allowed to
                    worship men. They say it is wrong to do so, and it may be, although we raise up on altars many
                    that have neither the talent nor the virtue of Manzoni and indeed are perfect scoundrels.”</i>
            </p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default VerdiPage
